*,
*::after,
*::before {
    box-sizing: border-box;
}

.container-u {
    padding: 26px;

}

.title {
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
    /* identical to box height */

    letter-spacing: -0.36px;

    color: #4A4A4A;

    text-align: left;
    display: flex;

}

.number {
    margin-left: 10px;
    color: #808080;
    font-weight: 400;

}

.photo-collection {
    display: grid;
    grid-template-columns: repeat(4, 4fr);
    gap: 50px;
}

.photo-container {
    height: 267px;
    width: 100%;
    max-width: 306px;
    cursor: pointer;
}

.photo-container>img {
    height: 219px;
    width: 100%;
    object-fit: cover;
    /* object-position: -1px -35px; */
}

.gen1 {
    /* object-position: 0px !important; */
}

.photo-container:hover {
    background-color: #F5F5F5;
    border-radius: 10px;
}

.photo-name {
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;

    color: #6C757D;
    text-align: left;

    height: 49px;
    padding: 16px;
}


.container-u2 {
    width: 100%;
    display: flex;

}

.container-u50 {
    width: 50%;
}

.one-photo-container {
    padding: 26px;
    display: flex;
    height: 750px;
    flex-direction: column;
    justify-content: space-between
}



/* one photo */


.onephoto-title {
    font-size: 24px;
    line-height: 29px;

}

.controllers {
    display: flex;
    width: 200px;
    justify-content: flex-end;
    align-items: center
}

.controller {

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-transform: capitalize;

    width: 130px;
    height: 44px;
    padding: 10px;

    color: #808080;
    background-color: white;
    border: none;
    cursor: pointer;

}

.controllers>button:hover {
    background-color: #4A4A4A;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    background: #E5F1F8;
    border-radius: 10px;

    /* text */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-transform: capitalize;

    color: #0C2340;

}

.controller>img {
    margin: 0px 12px;
}

.photo-viewer {
    width: 100%;
    height: 500px;
    /* margin-top: 28px; */
}

.photo-viewer>img {
    width: inherit;
    height: inherit;
    display: block;
    margin: auto;
    object-fit: contain;

}

.photo-viewer>.img-view {
    /* margin: 0 50%; */
}


.photo-name-container {
    /* text */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */


    color: #808080;
    text-align: left;
    margin-top: 25px;
}

.archive-label{
    margin-bottom: 6px;
}

.photo-name-input {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;

    color: #4A4A4A;
    width: 100%;
    height: 48px;

    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    border-radius: 4px;
    border-radius:  5px 0px 0px 5px;

    padding: 0px 43px;
    position: relative;

}

.photo-name-input-disabled {
    border: 1px solid #FFF;
    padding: 0px;
}

.rename-photo {
    display: flex;
}

.save-name-photo-btn {
    width: 100px;
    height: 48px;
    outline: none;
    /* border: none; */
    border-radius: 0px 5px 5px 0px;
    border: 1px solid #C4C4C4;
    font-family: 'Inter';
    font-style: normal;
    font-size: 19px;
    color: #0C2340;
    font-family: 'Inter';
    font-weight: 500;

}

::placeholder {
    color: #4A4A4A;
    /* opacity: 1; Firefox */
}

.photo-form {
    position: relative;
}

.edit-logo-new {
    position: absolute;
    top: 35px;
    left: 13px;
}

label {
    position: relative;
}

.photo-name-onephoto {
    margin-right: 10px;
    position: relative;
    top: -35px;
    left: 13px;

}

.generation-container {
    padding: 20px;
}

/* generationsmenu */
.generation {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height */

    letter-spacing: 0.05em;
    text-transform: uppercase;

    color: #808080;
    text-align: left;
}

.buttons-container {
    margin: 10px;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, 3fr);
    margin-bottom: 28px;
    margin-left: 0px;
}

.blue-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 10px;
    gap: 12px;

    width: 193px;
    height: 66px;

    background: #E5F1F8;
    border: 1px solid #BADAED;
    border-radius: 32px;

    text-align: left;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-transform: capitalize;

    color: #0C2340;
}

.markedAlbum {
    background-color: #BADAED;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.markedAlbum>img {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.blue-button:hover {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 10px;
    gap: 12px;

    width: 193px;
    height: 66px;

    background: #BADAED;
    border: 1px solid #BADAED;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 32px;
}

.blue-button>img {
    width: 42px;
    min-width: 42px;
    height: 42px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
}

.blue-button:hover>img {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

button.blue-button:focus {
    background: #0C2340;
    border: 1px solid #BADAED;
    color: #E5F1F8;
}

label {
    width: 100%;
}