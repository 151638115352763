*,
*::after,
*::before {
    box-sizing: border-box;
}

html {
    overflow-y: scroll;
  }

/* .dashboard{
} */

.nav-files {
    background-color: #F5F5F5;
    height: 71px;
    text-align: left;
    padding-left: 44px;
    position: relative;
    border-bottom: #D9D9D9;
    border-style: solid;
    border-width: 0px 0px 1px 0px;
}

.elements-container {
    position: absolute;
    bottom: -3px;
    left: 44px;
}

.nav-element {
    background-color: #F5F5F5;
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    border: none;

    width: 220px;
    height: 56px;
    color: #808080;
    padding-left: 33px;
    text-align: left;
    border-color: #C0C0C0;
    border-style: solid;
    border-width: 0px 1px 0px 0px;
    cursor: pointer;
    position: absolute;
    bottom: 2px;
    /* display: flex; */

}

.nav-element[active="yes"] {
    background: #FFFFFF;
    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: #C4C4C4;
    border-radius: 12px 12px 0px 0px;
    margin-bottom: 0px;
    border-bottom: 3px solid white;
    padding-bottom: 2px;

    /* text */
    font-family: 'Inter';
    font-style: normal;
    letter-spacing: 0.05em;
    text-transform: uppercase;


    color: #0C2340;
    cursor: pointer;
    position: absolute;
    bottom: 2px;
    z-index: 1;

}



.nav1 {}

.nav-element[active="no"] {

    height: 30px;
    padding-bottom: 30px;
    margin-bottom: 10px;

}

.nav-element:hover[active="no"] {
    border-radius: 12px 12px 0px 0px;
    width: 220px;
    height: 56px;
    padding-bottom: 7px;
    margin-bottom: 0px;

    background: #ececec;
    border-width: 1px 1px 0px 0px;
    border-style: solid;
    border-color: #C4C4C4;
}

.nav-element[active="yes"]:after {
    left: 0;
    content: '';
    height: 3px;

    position: absolute;
    bottom: -4px;
    width: 100%;
    background-color: white;
}

.nav2[active="no"] {
    left: 220px;
}

.nav3[active="no"] {
    left: 440px;
}

.nav4[active="no"] {
    left: 660px;
    width: 217px;
}

.nav1:hover[active="no"] {
    border-width: 1px 1px 0px 1px;
    padding-left: 32px;
}

.nav2[active="yes"] {
    left: 219px;
}

.nav2:hover[active="no"] {
    left: 218px;
    padding-left: 34px;
    border-width: 1px 1px 0px 1px;
    width: 221px;
}

.nav3[active="yes"] {
    left: 438px;
    
}

.nav3:hover[active="no"] {
    left: 438px;
    padding-left: 34px;
    border-width: 1px 1px 0px 1px;
    width: 221px;
    
}

.nav4[active="yes"] {
    left: 658px;
}

.nav4:hover[active="no"] {
    left: 657px;
    padding-left: 35px;
    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: #C4C4C4;
    
}