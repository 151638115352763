*, *::after, *::before {
    box-sizing: border-box;
}


.top-bar-website{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

}

.top-bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

}
.Dropdown-control{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    gap: 18px;
    width: 360px;
    height: 52px;
    
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    border-radius: 7px !important;

    /* TEXT */
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;

    color: #808080 !important;

    cursor: pointer !important;
}

.dropdown-styling{
    width: 360px;
    height: 52px;

    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    border-radius: 7px;

    /* /text */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;

    color: #808080;

    padding-left: 15px;

}

.szh-menu {
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    border-radius: 7px;
    width: 360px;
    height: auto;
    padding: 16px;

}

.arrow{
    position: absolute;
    right: 44px;
    z-index: 2;
}

.new-arrow{
    transform: rotate(180deg);
}

.select-album{
    position: relative;
}

.select-album>img{
    position: absolute;
    right: 15px;
    bottom: 18px;
}

.myMenuClassName{
    position: absolute ;
    width: 100%;
    background-color: white;
    top: 50px;
    left :0;
    padding: 16px;
    max-height: 675px !important;
    text-align: left ;

}

.top-option{
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;
    color: #404040;
    padding: 10px 0;
    text-align: left;
}

.Dropdown-title{
    border-top: 1px solid #C4C4C4;
    font-weight: 500 !important;
    font-size: 15px !important;
    line-height: 18px !important;
    text-transform: uppercase !important;

    color: #404040 !important;
    padding-top: 14px !important;
}

.myOptionClassName{
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 400 !important;
    font-size: 20px !important;
    line-height: 24px !important;
    display: flex;
    align-items: center;

    color: #808080 !important;
    padding-left: 30px !important;

}

.first-options-group{
    border-bottom: 1px solid #C4C4C4;
}

.Dropdown-arrow{
    top: 24px !important;
}

.error-container{
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
    margin-top: 20%;
}

.error-text{
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
    color: #808080;
    text-align: center;
}

.szh-menu-button{
    position: absolute;
    right: 29px;
}

.section-title{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;

    color: #404040;
    text-align: left;
    margin: 10px 0;
}

.absolute{
    position: absolute;
    top: 150px;
    background: white;
    width: 50%;
    width: 30%;
    height: 90px;

}

