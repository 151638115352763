

/* @media only screen and (max-width: 1440px) { */
*, *::after, *::before {
    box-sizing: border-box;
}
    .container {
        height: 100vh;
        background-image: url(./../../assets/images/gen-1-background.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

    }
    
    .login-wrapper{
        padding: 120px 105px;
        position: relative;
    }
    
    .Login {
      width: 500px;
      height: 680px;
      background-color: white;
      position: absolute;
      right: 105px;
      border-radius: 12px;
      padding: 63px 46px;
    }

    .login-title{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 26px;
        line-height: 31px;
        text-align: left;
        margin-bottom: 20px;
        height: 53px;

    }
  
    .Login form {
        text-align: left
    }
    
    label{
        /* font-family: 'Inter'; */
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #808080;
        text-align: left
    }

    input{
        width: 100%;
        background: #FFFFFF;
        border: 1px solid #C4C4C4;
        border-radius: 4px;
        height: 48px;

        /* text */

        /* font-family: 'Inter'; */
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        display: flex;
        align-items: center;

        color: #4A4A4A;


        padding: 10px;
        margin-top: 12px;
        margin-bottom: 24px;
    }

    .button-wrapper{
        text-align: center;
        text-align: center;
        display: flex;
        justify-content: center;
    }

    .sumbit-button{
        width: 215px;
        height: 49px;

        background: #0C2340;
        border-radius: 31px;

        color: white;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-align: center;

        display: flex;
        justify-content: center;
        align-items: center;

        margin-top: 20px;
    }

    .fit-link{
        color: white;
        text-decoration: none;

        width: 215px;
        height: 49px;
        border-radius: 31px;
    }

    .login-warning{

        color:red;
        margin:20px;
    }



  /* } */