
*, *::after, *::before {
    box-sizing: border-box;
}

.header{
    width: 100%;
    height: 71px;
    background-color: white;
    margin-top: 0px;
    padding-top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.header-text{
    font-family: 'Bodoni Moda';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 31px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 1px;

    opacity: 0.8;
}