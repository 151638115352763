.archived-name{
    margin: auto;
    position: relative;
    width: 700px;
}

.display-center{
    display: flex;
    align-items: center;
    justify-content: center;
}

.edit-logo{
    position: absolute;
    left: 13px;
    bottom: 13px;
}

.archived-name label{
    margin-bottom: 5px;
    margin-top: 10px;
}

a{
    text-decoration: none !important;
  }

.loader{
    position: absolute;
    right: -61px;
    top: 24px;
}