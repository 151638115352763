.trash-info{
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    text-align: center;
    letter-spacing: -0.36px;

    color: #6C757D;

    display: flex;

}

.trash-info>img{
    margin-left: 7px;
}